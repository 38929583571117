import React from 'react';
import ReactDOM from 'react-dom';
import { NativeBaseProvider } from 'native-base';
import { Provider } from 'react-redux';
import { ReactNotifications } from 'react-notifications-component';
import Routes from './routes/routes';
import GlobalStyle from './styles/global';
import AuthProvider from './hooks/useAuth';
import { theme } from './styles/theme';
import { store } from './store';
import 'react-notifications-component/dist/theme.css';
import { NetworkStatus } from './shared/NetworkStatus';
import './styles/global-settings.css';

ReactDOM.render(
  <Provider store={store}>
    <NativeBaseProvider theme={theme}>
      <React.StrictMode>
        <ReactNotifications />
        <AuthProvider>
          <GlobalStyle />
          <NetworkStatus />
          <Routes />
        </AuthProvider>
      </React.StrictMode>
    </NativeBaseProvider>
  </Provider>,
  document.getElementById('root'),
);
