import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  AnswerQuotation,
  Quotation,
} from '../../../../../../../@types/interface';
import {
  approveQuotationController,
  getQuotationByIdController,
} from '../../../controller';
import {
  removeAnswer,
  setApprovedQuotationList,
  updateAnsweredQuotation,
} from '../../../../../../../features/quotation/quotation-reply-slice';
import { useAppSelector } from '../../../../../../../hooks/useAppSelector';
import { numberOnly } from '../../../../../../../services/helpers/mask';
import { sendWhatsappMessage } from '../../../../../../../utils/sendWhatsappMessage';
import { ApprovedAnswer } from '../../../../../../../features/quotation/interfaces';
import { useQuotation } from '../../../../../../../contexts/quotationContext';
import useSelectedQuotation from '../../../hooks/useSelectedQuotation';

interface Props {
  // selectedQuotation?: Quotation | null;
  quotationId: string;
  isOpen: boolean;
  onClose: () => void;
}

export const useViewReplies = ({
  // selectedQuotation = null,
  quotationId,
  isOpen,
  onClose,
}: Props) => {
  // const [quotationReplies, setQuotationReplies] = useState<Quotation | null>(
  //   selectedQuotation,
  // );
  const selectedQuotation = useSelectedQuotation();
  const [isLoading, setIsLoading] = useState(false);
  const [modalStatus, setModalStatus] = useState<
    'sending' | 'success' | 'error'
  >('sending');
  const [isApprovingModalOpen, setIsApprovingModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [errorModalMessage, setErrorModalMessage] = useState('');
  const [isTrembleWarning, setIsTrembleWarning] = useState(false);
  const { updateQuotations } = useQuotation();

  const dispatch = useDispatch();
  const { approvedQuotation } = useAppSelector(state => state.quotationReducer);

  const fetchQuotationById = useCallback(
    async (id = selectedQuotation?.quotation?.id_quotation) => {
      setIsLoading(true);
      if (id) {
        const res = await getQuotationByIdController(id || '');
        if (res) {
          dispatch(
            setApprovedQuotationList({
              list_answers_approved:
                res.answers_quotation
                  ?.filter(answer => answer.answered)
                  ?.filter(answer => !answer.confirmed)
                  ?.map(answer => ({
                    answer_quotation_id: answer.id_answer_quotation,
                    rebuttal: '',
                    items: [],
                  })) || [],
              quotation_id: res.id_quotation,
            }),
          );
          selectedQuotation.onSelect(res);
          // set quotation as visualized
          updateQuotations(res);

          // setQuotationReplies(res);

          // dispatch(updateAnsweredQuotation(res));

          if (res.answers_quotation) {
            const answersList = res.answers_quotation
              .filter(answer => !answer.confirmed)
              .map(answer => ({
                answer_quotation_id: answer.id_answer_quotation,
                items: [],
                rebuttal: '',
              }));
            setApprovedQuotationList({
              quotation_id: res.id_quotation,
              list_answers_approved: answersList,
            });
          }
        }
      }
      setIsLoading(false);
    },
    [dispatch, selectedQuotation?.quotation?.id_quotation, updateQuotations],
  );

  const hasInvalidAnswers = (answers: ApprovedAnswer[]) => {
    return answers
      .map(answer => ({
        ...answer,
        items: answer?.items?.filter(item => item.quantity_asked === 0) ?? [],
      }))
      .some(answer => answer.items.length > 0);
  };

  const handleApproveQuotation = async () => {
    if (approvedQuotation) {
      if (hasInvalidAnswers(approvedQuotation.list_answers_approved)) {
        alert(
          'Verifique suas respostas, a quantidade solicitada deve ser maior que zero e dentro da quantidade disponível',
        );
        handleCloseModal();
      } else {
        setIsApprovingModalOpen(true);
        setModalStatus('sending');
        const formattedAnswers = approvedQuotation.list_answers_approved.map(
          answer => ({
            ...answer,
            items: answer.items
              .map(item => ({
                item_answer_quotation_id: item.item_answer_quotation_id,
                quantity_asked: item.quantity_asked,
              }))
              .filter(item => item.quantity_asked > 0),
          }),
        );

        const res = await approveQuotationController({
          ...approvedQuotation,
          list_answers_approved: formattedAnswers,
        });
        if (res.status === 'success') {
          setModalStatus('success');

          handleCloseModal();
          onClose();
        } else {
          setErrorModalMessage(res.data as string);
          setModalStatus('error');
        }
      }
    }
  };

  const handleApproveAnswerById = async (idAnswer: string) => {
    console.log('id: ', idAnswer);
    if (approvedQuotation) {
      const selectedAnswerToApprove =
        approvedQuotation.list_answers_approved.filter(
          answer => answer.answer_quotation_id === idAnswer,
        );

      if (hasInvalidAnswers(selectedAnswerToApprove)) {
        alert(
          'Verifique suas respostas, a quantidade solicitada deve ser maior que zero e dentro da quantidade disponível',
        );
      } else {
        setIsApprovingModalOpen(true);
        setModalStatus('sending');

        const formattedAnswer = approvedQuotation.list_answers_approved
          .filter(answer => answer.answer_quotation_id === idAnswer)
          .map(answer => ({
            ...answer,
            items: answer.items
              .map(item => ({
                item_answer_quotation_id: item.item_answer_quotation_id,
                quantity_asked: item.quantity_asked,
              }))
              .filter(item => item.quantity_asked > 0),
          }));

        const res = await approveQuotationController({
          ...approvedQuotation,
          list_answers_approved: formattedAnswer,
        });
        if (res.status === 'success') {
          setModalStatus('success');
          setIsApprovingModalOpen(false);
          dispatch(removeAnswer({ answer_quotation_id: idAnswer }));
          if (typeof res.data === 'object') {
            const updatedAnswersQuotation = res.data as AnswerQuotation[];
            const filteredAnswerQuotation =
              selectedQuotation.quotation?.answers_quotation?.filter(
                answer => answer.id_answer_quotation !== idAnswer,
              ) || [];
            selectedQuotation.onSetAnswers([
              ...filteredAnswerQuotation,
              ...updatedAnswersQuotation,
            ]);
            // setQuotationReplies(previous => {
            //   if (!previous) return null;
            //   return {
            //     ...previous,
            //     answers_quotation: [
            //       ...filteredAnswerQuotation,
            //       ...updatedAnswersQuotation,
            //     ],
            //   };
            // });
          }
        } else {
          setErrorModalMessage(res.data as string);
          setModalStatus('error');
          setIsApprovingModalOpen(false);
        }
      }
    }
  };

  const handleRejectAnswerById = async (idAnswer: string) => {
    if (approvedQuotation) {
      setIsApprovingModalOpen(true);
      setModalStatus('sending');

      const formattedAnswer = approvedQuotation.list_answers_approved
        .filter(answer => answer.answer_quotation_id === idAnswer)
        .map(answer => ({
          ...answer,
          items: [],
        }));

      const res = await approveQuotationController({
        ...approvedQuotation,
        list_answers_approved: formattedAnswer,
      });
      if (res.status === 'success') {
        setModalStatus('success');
        setIsApprovingModalOpen(false);
        dispatch(removeAnswer({ answer_quotation_id: idAnswer }));
        if (typeof res.data === 'object') {
          const updatedAnswersQuotation = res.data as AnswerQuotation[];
          const filteredAnswerQuotation =
            selectedQuotation.quotation?.answers_quotation?.filter(
              answer => answer.id_answer_quotation !== idAnswer,
            ) || [];

          selectedQuotation.onSetAnswers([
            ...filteredAnswerQuotation,
            ...updatedAnswersQuotation,
          ]);
          // setQuotationReplies(previous => {
          //   if (!previous) return null;
          //   return {
          //     ...previous,
          //     answers_quotation: [
          //       ...filteredAnswerQuotation,
          //       ...updatedAnswersQuotation,
          //     ],
          //   };
          // });
        }
      } else {
        setErrorModalMessage(res.data as string);
        setModalStatus('error');
        setIsApprovingModalOpen(false);
      }
    }
  };

  const handleCloseModal = () => {
    setIsApprovingModalOpen(false);
    setModalStatus('sending');
    setIsConfirmModalOpen(false);
  };

  const closeErrorModal = () => {
    setErrorModalMessage('');
  };

  const handleConfirmModal = (option: 'open' | 'close', invalid?: boolean) => {
    if (option === 'open') {
      if (invalid !== true) {
        setIsTrembleWarning(false);
        return setIsConfirmModalOpen(true);
      }
      setIsTrembleWarning(true);
    } else {
      setIsConfirmModalOpen(false);
    }
  };

  const handleInvalid = (invalid: boolean) => {
    if (!invalid) {
      setIsTrembleWarning(false);
    }
  };

  const handleWhatsappMessage = (
    phone: string,
    quotationNumber: Quotation | null,
  ) => {
    if (phone && quotationNumber && quotationNumber.QUOTATION) {
      const Text = `Olá, gostaria de falar sobre a cotação N° ${quotationNumber.QUOTATION} gerada pelo *Autocenter*`;

      sendWhatsappMessage(Text, `${numberOnly(phone)}`);
    }
  };

  useEffect(() => {
    if (quotationId && quotationId.length > 0 && isOpen) {
      fetchQuotationById(quotationId);
    }
  }, [fetchQuotationById, isOpen, quotationId]);

  useEffect(() => {
    if (isOpen) {
      fetchQuotationById();
    }
  }, [fetchQuotationById, isOpen]);

  // useEffect(() => {
  //   alert('esse 1');
  //   if (selectedQuotation?.quotation?.id_quotation && isOpen) {
  //     fetchQuotationById(selectedQuotation?.quotation?.id_quotation ?? '');
  //   }
  //   return () => {
  //     // setQuotationReplies(null);
  //     selectedQuotation.onClear();
  //     setApprovedQuotationList(null);
  //   };
  // }, [fetchQuotationById, isOpen, selectedQuotation?.quotation?.id_quotation]);

  // useEffect(() => {
  //   alert('esse 2');
  //   if (
  //     selectedQuotation.quotation &&
  //     selectedQuotation.quotation.answers_quotation
  //   ) {
  //     const answersList = selectedQuotation.quotation.answers_quotation
  //       .filter(answer => !answer.confirmed)
  //       .map(answer => ({
  //         answer_quotation_id: answer.id_answer_quotation,
  //         items: [],
  //         rebuttal: '',
  //       }));
  //     setApprovedQuotationList({
  //       quotation_id: selectedQuotation.quotation.id_quotation,
  //       list_answers_approved: answersList,
  //     });
  //   }
  // }, [selectedQuotation.quotation]);

  return {
    // quotationReplies,
    isLoading,
    handleApproveQuotation,
    modalStatus,
    handleCloseModal,
    isApprovingModalOpen,
    isConfirmModalOpen,
    handleConfirmModal,
    errorModalMessage,
    closeErrorModal,
    isTrembleWarning,
    handleInvalid,
    handleWhatsappMessage,
    handleApproveAnswerById,
    handleRejectAnswerById,
    approvedQuotation,
    hasInvalidAnswers,
  };
};
