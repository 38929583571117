/* eslint-disable react/jsx-no-constructed-context-values */
import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { getAccessToken, getWorkshopData } from '../utils/workshopData';
import { LoginModal } from '../shared/LoginModal';
import api from '../services/api/api';
import { AuthenticationResponse, Workshop } from '../@types/interface';
import useLoginModal from './useLoginModal';

interface IUserProvider {
  workshop: Workshop | null;
  setWorkshop: React.Dispatch<React.SetStateAction<Workshop | null>>;
  authenticate: () => void;
}

const AuthContext = createContext({} as IUserProvider);

const AuthProvider: React.FC<PropsWithChildren<any>> = ({ children }) => {
  const [workshop, setWorkshop] = useState<Workshop | null>(null);
  const loginModal = useLoginModal();

  useEffect(() => {
    const fetchWorkshopData = async () => {
      try {
        const data = await getWorkshopData();
        setWorkshop(data);
      } catch (error) {
        setWorkshop(null);
      }
    };

    const token = localStorage.getItem('@AutoCenter: accessToken') ?? '';
    if (token && token.length > 0) {
      fetchWorkshopData();
    }
  }, []);

  const revalidate = useCallback(async () => {
    try {
      const { data } = await api.post<AuthenticationResponse>('/auth/token');
      if (data) {
        localStorage.setItem('@AutoCenter: accessToken', data.accessToken);
      }
    } catch (err) {
      loginModal.onOpen();
      console.error(err);
    }
  }, [loginModal.onOpen]);

  const authenticate = useCallback(async () => {
    const accessToken = getAccessToken();
    if (
      // !localUser ||
      !accessToken
    ) {
      window.location.href = '/page-login';
      return;
    }
    await revalidate();
  }, [revalidate]);

  return (
    <AuthContext.Provider value={{ workshop, setWorkshop, authenticate }}>
      {children}
      <LoginModal />
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => useContext(AuthContext);
