import { BrowserRouter } from 'react-router-dom';
import { useEffect, Suspense, lazy, useCallback, useState } from 'react';
import { getWorkshopData } from '../utils/workshopData';
import { ReportRoutes } from './reports/routes';
import { CommonRoutes } from './common/routes';
import Fallback from '../shared/Fallback/Fallback';
import { NotificationProvider } from '../contexts/notificationContext';
import { Workshop } from '../@types/interface';

const WorkshopRoutes = lazy(() => import('./workshop/routes'));
const FleetRoutes = lazy(() => import('./fleet/routes'));
const GasStationRoutes = lazy(() => import('./gasStation/routes'));

const Routes = () => {
  const [accessType, setAccessType] = useState('');
  const [workshopData, setWorkshopData] = useState<Workshop | null>(null);

  useEffect(() => {
    const fetchAccessType = async () => {
      try {
        const res = await getWorkshopData();
        if (res && res.type) {
          setWorkshopData(res);
          setAccessType(res.type.toLocaleUpperCase());
        } else {
          setAccessType('');
        }
      } catch (error) {
        setAccessType('');
      }
    };

    const token = localStorage.getItem('@AutoCenter: accessToken');


    if (token && token.length > 0) {
      fetchAccessType();
    }
  }, []);

  const handleAccessType = useCallback(() => {
    switch (accessType) {
      case 'GAS_STATION':
        return (
          <>
            <ReportRoutes workshopData={workshopData} />
            <Suspense fallback={<Fallback />}>
              <GasStationRoutes />
            </Suspense>
            <CommonRoutes />
          </>
        );
      case 'FLEET':
        return (
          <>
            <ReportRoutes workshopData={workshopData} />
            <Suspense fallback={<Fallback />}>
              <FleetRoutes />
            </Suspense>
            <CommonRoutes />
          </>
        );
      default:
        return (
          <>
            <ReportRoutes workshopData={workshopData} />
            <Suspense fallback={<Fallback />}>
              <WorkshopRoutes />
            </Suspense>
            <CommonRoutes />
          </>
        );
    }
  }, [accessType, workshopData]);

  return (
    <BrowserRouter>
      <NotificationProvider>{handleAccessType()}</NotificationProvider>
    </BrowserRouter>
  );
};

export default Routes;
