import { Plus, X } from 'phosphor-react';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Checkbox } from '../Checkbox';
import styles from './select-component.module.css';
import { Loading } from '../Loading';

export interface Option {
  name: string;
  value: string;
}

export interface OptionList {
  option: Option;
  selected: boolean;
}

interface Props {
  options: Option[];
  onSelect?: (option: OptionList) => void;
  onSearch?: (query: string) => void;
  onAddOptions?: (options: Option[]) => void;
  isLoading?: boolean;
  currentSelection?: OptionList[];
}

export const SelectComponent = ({
  options,
  onSelect,
  onAddOptions,
  onSearch,
  isLoading = false,
  currentSelection = [],
}: Props) => {
  const [search, setSearch] = useState('');
  const [optionList, setOptionList] = useState<OptionList[]>(
    options.map(option => ({
      selected: currentSelection.some(
        selectedItem => selectedItem.option.value === option.value,
      ),
      option,
    })),
  );

  const [isOpen, setIsOpen] = useState(false);

  // Atualizar optionList quando options muda
  useEffect(() => {
    setOptionList(
      options.map(option => ({
        selected: currentSelection.some(
          selectedItem => selectedItem.option.value === option.value,
        ),
        option,
      })),
    );
  }, [options, currentSelection]);

  const selectedItems = useMemo(
    () => optionList.filter(option => option.selected),
    [optionList],
  );

  const handleClearSearch = useCallback(() => {
    setSearch('');
    setIsOpen(false);
  }, []);

  const handleInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setSearch(value);
      if (onSearch) onSearch(value);
      setIsOpen(true);
    },
    [onSearch],
  );

  const handleOptionChange = useCallback(
    (selectedOption: OptionList) => {
      setOptionList(prevOptions =>
        prevOptions.map(option =>
          option.option.value === selectedOption.option.value
            ? { ...option, selected: !option.selected }
            : option,
        ),
      );
      if (onSelect)
        onSelect({ ...selectedOption, selected: !selectedOption.selected });
    },
    [onSelect],
  );

  return (
    <div className={styles.container}>
      <div className={styles['input-wrapper']}>
        <input
          type="text"
          placeholder="pesquise por nome, peça ou serviço"
          value={search}
          onChange={handleInputChange}
          onClick={() => {
            setIsOpen(true);
            if (onSearch) onSearch(search);
          }}
        />
        <button
          type="button"
          className={styles['clear-button']}
          onClick={handleClearSearch}
        >
          <X size={16} />
        </button>
      </div>
      {isOpen && (
        <div className={styles['list-wrapper']}>
          {isLoading ? (
            <div className={styles.loader}>
              <Loading />
              <Loading />
              <Loading />
            </div>
          ) : (
            <>
              <ul className={styles.options}>
                {optionList.map(item => (
                  <li
                    key={item.option.value}
                    className={item.selected ? `${styles.selected}` : ''}
                  >
                    <Checkbox
                      isChecked={item.selected}
                      name={item.option.value}
                      theme="green"
                      customSize="small"
                      handleChange={() => handleOptionChange(item)}
                      label={item.option.name}
                    />
                  </li>
                ))}
              </ul>

              <div className={styles['selection-resume']}>
                {selectedItems.length > 0 ? (
                  <button
                    type="button"
                    onClick={() => {
                      handleClearSearch();
                      if (onAddOptions)
                        onAddOptions(
                          optionList
                            .filter(option => option.selected)
                            .map(item => item.option),
                        );
                    }}
                  >
                    <Plus size={18} />
                    Adicionar {selectedItems.length} item(s)
                  </button>
                ) : (
                  'selecione alguma opção'
                )}
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};
