import { useForm } from 'react-hook-form';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  answerQuotationController,
  getAnswerQuotationByIdController,
} from '../../../../controller';
import {
  AnswerQuotation,
  Workshop,
} from '../../../../../../../../@types/interface';
import {
  updateNotAnsweredQuotation,
  updateNotApprovedQuotation,
} from '../../../../../../../../features/answerQuotation/answer-quotation';
import { sendWhatsappMessage } from '../../../../../../../../utils/sendWhatsappMessage';
import { getWorkshopData } from '../../../../../../../../utils/workshopData';
import { useAnswerQuotation as useAnswer } from '../../../../../../../../contexts/answerQuotationContext';

export interface SubItem {
  total: string;
  quantity_available: string;
  brand?: string;
  reference?: string;
}

export interface formItemQuotation {
  item_quotation_id: string;
  description: string;
  informedReference: string;
  quantity: string;
  sub_items: SubItem[];
}

export interface FormValues {
  quotationId: string;
  comment: string;
  items: formItemQuotation[];
  tax_value: number;
}

interface Props {
  answer: AnswerQuotation | null;
  isOpen: boolean;
  localAnswerId?: string;
  onClose: () => void;
}

const defaultValues: FormValues = {
  quotationId: '',
  comment: '',
  items: [],
  tax_value: 0,
};

const schema = yup.object().shape({
  comment: yup.string(),
  tax_value: yup.number(),
  items: yup.array().of(
    yup.object().shape({
      item_quotation_id: yup.string().required(),
      sub_items: yup.array().of(
        yup.object().shape({
          total: yup.number().nullable(),
          quantity_available: yup.string().nullable(),
          reference: yup.string().nullable(),
          brand: yup.string().when(['quantity_available', 'reference'], {
            is: (quantity_available: string, reference: string) =>
              (quantity_available !== null &&
                quantity_available !== undefined &&
                quantity_available.trim().length > 0) ||
              (reference !== null &&
                reference !== undefined &&
                reference.trim().length > 0),
            then: yup.string().required('O campo marca é obrigatório'),
            otherwise: yup.string().nullable(),
          }),
        }),
      ),
    }),
  ),
});

export const useAnswerQuotation = ({
  answer,
  isOpen,
  localAnswerId = '',
  onClose,
}: Props) => {
  const [status, setStatus] = useState<'sending' | 'success' | 'error'>(
    'sending',
  );
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
  const [errorModalMessage, setErrorModalMessage] = useState('');
  const [selectedAnswer, setSelectedAnswer] = useState<AnswerQuotation | null>(
    null,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const { control, reset, getValues, watch, trigger } = useForm<FormValues>({
    defaultValues,
    resolver: yupResolver(schema),
  });
  const dispatch = useDispatch();

  const formQuotationItems = watch('items');

  const { updateAnswerQuotations } = useAnswer();

  const [workshop, setWorkshop] = useState<Workshop | null>(null);

  useEffect(() => {
    const fetchWorkshopData = async () => {
      try {
        const data = await getWorkshopData();
        setWorkshop(data);
      } catch (error) {
        setWorkshop(null);
      }
    };

    fetchWorkshopData();
  }, []);

  const addSubItem = (index: number) => {
    const newSubItem = {
      reference: '',
      quantity_available: '',
      total: '0',
      brand: '',
    };
    const currentItems = getValues('items');
    currentItems[index].sub_items.push(newSubItem);
    reset({ ...getValues(), items: currentItems });
  };

  const removeSubItem = (indexItem: number, indexSubitem: number) => {
    const currentItems = getValues('items');
    currentItems[indexItem].sub_items.splice(indexSubitem, 1);
    reset({ ...getValues(), items: currentItems });
  };

  const handleAnswer = async () => {
    setIsFeedbackModalOpen(true);
    setStatus('sending');
    const formValues = getValues();

    const normalizedItems = formValues.items.map(item => ({
      item_quotation_id: item.item_quotation_id,
      sub_items: item.sub_items.map(subItems => ({
        total: subItems?.total
          ? parseFloat(subItems.total.replace(',', '.'))
          : 0,
        quantity_available: subItems?.quantity_available ?? '',
        brand: subItems.brand ?? '',
        reference: subItems.reference ?? '',
      })),
    }));

    const currentAnsweredItems = normalizedItems
      .map(item => ({
        ...item,
        sub_items: item.sub_items.filter(subItem => {
          const quantityAvailable = parseFloat(subItem.quantity_available);
          return (
            !isNaN(quantityAvailable) &&
            quantityAvailable > 0 &&
            subItem.total > 0
          );
        }),
      }))
      .filter(item => item.sub_items.length > 0); // Remove invalids Items from list

    const res = await answerQuotationController({
      quotation_id: formValues.quotationId,
      items: currentAnsweredItems,
      comment: formValues.comment,
      tax_value: formValues.tax_value,
    });
    if (res.status === 'success') {
      setStatus('success');
      if (answer) {
        dispatch(
          updateNotAnsweredQuotation({
            ...answer,
            answered: true,
          }),
        );
        handleCloseModal();
        onClose();
      }
    } else {
      setStatus('error');
      setErrorModalMessage(res.data as string);
    }
  };
  const handleResetStatus = () => {
    setStatus('sending');
    setIsFeedbackModalOpen(false);
  };

  const closeErrorModal = () => {
    setErrorModalMessage('');
  };

  const fetchAnswerQuotationById = useCallback(
    async (id: string) => {
      setIsLoading(true);
      const res = await getAnswerQuotationByIdController(id);
      if (res) {
        setSelectedAnswer(res);
        if (res) {
          updateAnswerQuotations(res);
          dispatch(updateNotApprovedQuotation(res));
        }
      }
      setIsLoading(false);
    },
    [dispatch, updateAnswerQuotations],
  );

  const handleCloseModal = useCallback(() => {
    setSelectedAnswer(null);
    setIsConfirmModalOpen(false);
    setIsFeedbackModalOpen(false);
    setStatus('sending');
    reset(defaultValues);
  }, [reset]);

  const handleCloseErrorModal = useCallback(() => {
    setIsFeedbackModalOpen(false);
    setStatus('sending');
  }, []);

  useEffect(() => {
    if (answer && isOpen) {
      fetchAnswerQuotationById(answer.id_answer_quotation);
    } else {
      setSelectedAnswer(null);
    }
    return () => setSelectedAnswer(null);
  }, [fetchAnswerQuotationById, answer, isOpen]);

  useEffect(() => {
    if (localAnswerId && localAnswerId.length > 0) {
      fetchAnswerQuotationById(localAnswerId);
    } else {
      setSelectedAnswer(null);
    }
    return () => setSelectedAnswer(null);
  }, [fetchAnswerQuotationById, answer, isOpen, localAnswerId]);

  const toggleModal = (option: 'open' | 'close') => {
    if (option === 'open') {
      setIsConfirmModalOpen(true);
    } else {
      setIsConfirmModalOpen(false);
    }
  };

  const handleConfirmModal = () => {
    toggleModal('close');
    handleAnswer();
  };

  const handleWhatsappMessage = (quotation: AnswerQuotation | null) => {
    if (quotation && quotation.quotation?.QUOTATION && quotation.workshop) {
      const Text = `Olá, sou da ${
        workshop?.fantasy_name ?? ''
      }. Poderia fornecer mais informações sobre a cotação de número ${
        quotation.quotation?.QUOTATION
      }?`;
      sendWhatsappMessage(Text, quotation?.quotation.workshop.whatsapp ?? '');
    }
  };

  return {
    handleAnswer,
    control,
    reset,
    status,
    isFeedbackModalOpen,
    handleResetStatus,
    errorModalMessage,
    closeErrorModal,
    formQuotationItems,
    selectedAnswer,
    isLoading,
    isConfirmModalOpen,
    setIsConfirmModalOpen,
    handleCloseModal,
    toggleModal,
    handleConfirmModal,
    handleWhatsappMessage,
    addSubItem,
    removeSubItem,
    handleCloseErrorModal,
    trigger,
  };
};
