import { getWorkshopData } from '../../utils/workshopData';

export const passwordHasChanged = async () => {
  const workshop = await getWorkshopData();

  if (workshop && workshop.user) {
    const { user } = workshop;
    return user?.is_first_access_after_recovery || user?.is_first_access;
  }

  return false;
};
